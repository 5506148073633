// const CACHE_NAME = 'v1';
// const urlsToCache = [
//   '/styles/main.css',
//   '/script/main.js',
//   '/images/logo.png',
//   // Add URLs of font files and other assets you want to cache
//   '/fonts/your-font-file.woff',
//   '/assets/some-image.png'
// ];

// // The 'install' event is called when the service worker is first installed
// // ... (the rest of the service worker code)

// // The 'install' event is called when the service worker is first installed
// self.addEventListener('install', function(event) {
//   event.waitUntil(
//     caches.open(CACHE_NAME)
//       .then(function(cache) {
//         console.log('Opened cache');
//         // Add each URL to the cache one by one and log any that fail
//         const cachePromises = urlsToCache.map(urlToCache => {
//           return cache.add(urlToCache).catch(error => {
//             console.error(`Caching failed for ${urlToCache}: ${error}`);
//             return Promise.reject(error);
//           });
//         });
//         return Promise.all(cachePromises);
//       })
//       .catch(error => {
//         // If any of the cache additions fail, this will be logged here
//         console.error(`Failed to install service worker: ${error}`);
//       })
//   );
// });
// // ... (the rest of the service worker code)

// // The 'activate' event is called when the service worker starts
// self.addEventListener('activate', function(event) {
//   console.log('Service worker activating...');

//   // Remove old caches that don't match the current version
//   event.waitUntil(
//     caches.keys().then(function(cacheNames) {
//       return Promise.all(
//         cacheNames.map(function(cacheName) {
//           if (cacheName !== CACHE_NAME) {
//             console.log('Deleting old cache:', cacheName);
//             return caches.delete(cacheName);
//           }
//         })
//       );
//     })
//   );
// });

// // The 'fetch' event is called when a network request is made
// self.addEventListener('fetch', function(event) {
//   event.respondWith(
//     caches.match(event.request)
//       .then(function(response) {
//         // Cache hit - return the response from the cached version
//         if (response) {
//           return response;
//         }

//         // IMPORTANT: Clone the request. A request is a stream and can only be consumed once.
//         // Since we are consuming this once by cache and once by the browser for fetch, we need to clone the response.
//         var fetchRequest = event.request.clone();

//         return fetch(fetchRequest).then(
//           function(response) {
//             // Check if we received a valid response
//             if (!response || response.status !== 200 || response.type !== 'basic') {
//               return response;
//             }

//             // IMPORTANT: Clone the response. A response is a stream and because we want the browser to consume the response
//             // as well as the cache consuming the response, we need to clone it so we have two streams.
//             var responseToCache = response.clone();

//             caches.open(CACHE_NAME)
//               .then(function(cache) {
//                 cache.put(event.request, responseToCache);
//               });

//             return response;
//           }
//         );
//       })
//   );
// });

// Copyright 2016 Google Inc.
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//      http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.



// Increase this version to deploy new code
const swVersion = '1.28';

const hostPrefix = '';
var dataCacheName = 'rock-pess-data';
var cacheName = 'rock-press';

// Static resource to be cached.
var filesToCache = [
   '/styles/main.css',
  '/script/main.js',
  '/images/logo.png',
  // Add URLs of font files and other assets you want to cache
  '/fonts/',
  '/assets/some-image.png',
  '/icons/'
];

// Service worker install hook.
self.addEventListener('install', function(e) {
  console.log('[ServiceWorker] Install');
  e.waitUntil(
    caches.open(cacheName).then(function(cache) {
      console.log('[ServiceWorker] Caching app shell');
      return cache.addAll(filesToCache);
    })
  );
});

// Service worker activation hook.
self.addEventListener('activate', function(e) {
  console.log('[ServiceWorker] Activate');
  e.waitUntil(
    caches.keys().then(function(keyList) {
      return Promise.all(keyList.map(function(key) {
        if (key !== cacheName && key !== dataCacheName) {
          console.log('[ServiceWorker] Removing old cache', key);
          return caches.delete(key);
        }
      }));
    })
  );
  /*
   * Fixes a corner case in which the app wasn't returning the latest data.
   * You can reproduce the corner case by commenting out the line below and
   * then doing the following steps: 1) load app for first time so that the
   * initial New York City data is shown 2) press the refresh button on the
   * app 3) go offline 4) reload the app. You expect to see the newer NYC
   * data, but you actually see the initial data. This happens because the
   * service worker is not yet activated. The code below essentially lets
   * you activate the service worker faster.
   */
  return self.clients.claim();
});

self.addEventListener('fetch', function(e) {
  console.log('[Service Worker] Fetch', e.request.url);
  e.respondWith(
    caches.match(e.request).then(function (response) {
      return response || fetch(e.request);
    })
  );
});